export const primaryColor = "#1C3B61";
export const darkGray = "#424242";
export const lightGray = "#C4C4C4";
export const cream = "#061836";
export const gold = "#EBBA7D";
export const orange = "#DE6936";
export const red = "#BF0F0F";
export const darkBlue = "#061836";
export const logodark = "/images/Logo-Dark.png";
export const logo = "/images/Logo-White.png";
export const mobileWidth = "611px";
export const tabletWidth = "611px";
export const desktopWidth = "612px";
export const siteTitle = "Figdog";
export const siteSlogan = "PERSPECTIVES ON MARKETING AND MANAGED IT SERVICES";
